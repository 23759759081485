import React from "react"
import PropTypes from "prop-types"
import IGIcon from '../images/colored_ig_icon.png'
import MobileFlowers from '../images/mobile_flowers.png'
import BottomFlowers from '../images/bottom_floral.png'
import Title from './title'
import { Link } from "gatsby"

import 'typeface-work-sans'

import "./layout.css"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&"); }

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", signedUp: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "signup", ...this.state })
    }).then(() => {
      this.setState({signedUp: true});
    }).catch(error => alert(error));

    e.preventDefault();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { email, signedUp } = this.state;

    return(
      <main class='flex flex-col justify-start lg:justify-center h-screen lg:flex-wrap'>
        {this.props.children}
        <div class="left-column lg:w-2/5 lg:h-screen"> </div>

        <div class="lg:w-3/5 md:px-20 md:mt-10 lg:max-w-3xl">
          <div class='w-full mt-10 px-5'>
            <Title />
          </div>

          <div class='flex flex-col justify-between w-full px-5 py-10'>
            <h3 class='text-lightpink py-5'>
              Maybe you love movies.
            </h3>

            <h3 class='text-lightYellow py-5'>
              Maybe you're on a date.
            </h3>

            <h3 class='text-teal py-5'>
              Either way, explore unique film and avoid modern theater chains.
            </h3>
          </div>

          <span class='flex flex-col justify-between w-full px-5 pt-5'>
            <span class='text-right text-lightpink text-xs md:text-left md:text-sm font-light'>Coming to Brooklyn soon.</span>
            { !signedUp &&
              <form data-netlify="true" data-netlify-honeypot="bot-field" class='flex flex-col' onSubmit={this.handleSubmit} name="signup">
                <input type="hidden" name="form-name" value="signup" />
                <input class="placeholder-lightbeige appearance-none border border-teal-200 rounded w-full mt-2 py-2 px-4 text-beige leading-tight focus:outline-none focus:border-teal-400" type="email" placeholder="Email Address" name="email" value={email} onChange={this.handleChange} required
                />
                <div class='max-w-full'>
                  <button class="w-full shadow-md active:bg-pink hover:text-teal uppercase font-bold text-black mt-5 text-md py-2" type="submit">
                    Be the first
                  </button>
                </div>
              </form>
            }
            { signedUp &&
              <h3 class="py-5 text-center text-teal">You're on the list and we'll be in touch soon!.</h3>
            }
            <div class='filmmaker-link text-beige max-w-full py-5 flex justify-end md:justify-between'>
              <a class="self-start" target="_blank" rel='noopener noreferrer' href="https://instagram.com/thecinemagarden">
                <picture class='px-3'>
                  <source srcSet={IGIcon} />
                  <img class="w-6 inline" src={IGIcon} alt="" />
                </picture>
              </a>
              <Link class='self-end text-white text-sm italic' to='/filmmakers'>filmmaker?</Link>
            </div>
          </span>
        </div>
        <picture class='fixed bottom-0 left-0 -z-10'>
          <source srcSet={MobileFlowers} media="(max-width: 767px)"/>
          <source srcSet={BottomFlowers} media="(max-width: 1023px)"/>
          <img src="" alt="" />
        </picture>
      </main>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
