import React from "react";

const Title = props => (
  <svg viewBox="0 0 408.8 29.42" {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={17.29}
        x2={408.8}
        y2={17.29}
        gradientTransform="matrix(1 0 0 -1 0 32)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ffcfc9" />
        <stop offset={0.12} stopColor="#ffc9cc" />
        <stop offset={0.27} stopColor="#feb9d6" />
        <stop offset={0.37} stopColor="#feaadf" />
        <stop offset={0.42} stopColor="#fab3d9" stopOpacity={0.99} />
        <stop offset={0.5} stopColor="#f0cbc8" stopOpacity={0.97} />
        <stop offset={0.59} stopColor="#dff2ad" stopOpacity={0.94} />
        <stop offset={0.61} stopColor="#daffa4" stopOpacity={0.93} />
        <stop offset={0.7} stopColor="#94ffb1" stopOpacity={0.93} />
        <stop offset={0.85} stopColor="#1dffc8" stopOpacity={0.94} />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g data-name="Layer 2">
          <path
            d="M18.05.06a20.59 20.59 0 0111.19 3.17l.07.06L24 8.75c-3.45-2.21-9.29-2.4-13 .83-3.08 2.81-3.54 7.67.67 10.81a11.28 11.28 0 0012.33.28l5.33 5.49a20.13 20.13 0 01-7.42 2.89A21.84 21.84 0 016.05 25.7a16.45 16.45 0 01-2.58-2.28C-1.49 18-1.28 9.74 5.06 4.49A20.14 20.14 0 0118.05.06zm12.88 0h9.42v29.3h-9.42zm10.69 0h9.28l13.27 17.07V.06h9.53v29.3h-9.42L51 12.29v17.07h-9.38zm35.26 0h26.26v6.63H86.3v5h13.13v6.64H86.3v4.52h16.84v6.51H76.88zm46.44 0l6.57 18.58L136.35 0h10.8l4.34 29.33h-9.32l-2.58-16.58-5.71 16.58h-7.8l-5.72-16L118 29.36h-9.43L112.66 0zm44.68 0h8.79l13.66 29.3h-8.93l-2.36-4.91h-13.38l-2.47 4.91h-8.93zm4.34 9.83l-3.84 8.32h7.69zM227.4.06a20.5 20.5 0 0111.26 3.23c-3.41 3.5-5.19 5.33-5.33 5.46a11.4 11.4 0 00-12.71.6c-3.56 3-3.66 8.12.71 11.26 3 1.54 4.82 2.24 9 1.38v-5.86H239v9.63c-.08.25-.49.56-1.24.95a21.71 21.71 0 01-21.85-.66 15.77 15.77 0 01-2.56-2.05c-5.27-5.24-5.57-13.41.5-19a18.13 18.13 0 012.54-1.91A20.74 20.74 0 01227.4.06zm27.21 0h8.79l13.66 29.3h-8.93l-2.37-4.91h-13.37l-2.47 4.91H241zM259 9.89l-3.85 8.32h7.7zM293.86.06a14.45 14.45 0 016.35 1.28 12.16 12.16 0 012.4 1.49 10.32 10.32 0 012 2.12 8.19 8.19 0 01-1.16 10.66 13.16 13.16 0 01-3.11 2.17l7.8 11.58H298l-6.85-10.23h-3.7v10.23H278V.06zm-6.39 6.2v6.64c2.72-.17 7.4.89 9-2.24 1.74-4.39-5.66-4.78-9-4.4zM318.7.06c5.29-.25 11.37.19 16 3.4 6.88 4.36 9 14.07 2.58 20.36a17 17 0 01-3.28 2.54c-5.87 4.19-17.18 2.79-24.31 3V.06zm.42 7.14v15c3.94.2 8.86 0 11.69-3.35a6.59 6.59 0 00-.93-9.27l-.13-.11c-2.14-2.14-7.17-2.47-10.63-2.27zM345 .06h26.26v6.63h-16.84v5h13.13v6.64h-13.13v4.52h16.83v6.51H345zm31.7 0h9.3l13.27 17.07V.06h9.53v29.3h-9.43l-13.26-17.07v17.07h-9.42z"
            fill="url(#a)"
            data-name="Layer 1-2"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Title;
